import React from 'react';
import PropTypes from 'prop-types';

import JsonFormatter from '@shared/components/JsonFormatter';

const getError = error => ({
  message: error && error.message,
  stack: error && error.stack,
});

const ExceptionError = ({ error, title = null }) => {
  const exceptionError = error && error.name === 'ApiError' ? error : getError(error);
  return (
    <div>
      {title && <p>{title}</p>}
      <JsonFormatter data={exceptionError} />
    </div>
  );
};

ExceptionError.propTypes = {
  error: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

export default ExceptionError;
